<template>
  <div class="bgcard">
    <div class="bgcolor" v-if="devnone">
      <div class="equimentheader">
        <div class="equimentheaders">
          <div class="equimentheader_a">
            <div class="eqleft">
              <div class="imgcard">
                <img
                  class="imgcard_a"
                  v-if="devequipmen.modelName == 'uv800'"
                  src="../../../../assets/imgs/shebei_img.png"
                />
                <img
                  class="imgcard_a"
                  v-if="devequipmen.modelName == 'uv100'"
                  src="../../../../assets/imgs/shebei_img1.png"
                />
                <img
                  class="imgcard_a"
                  v-if="devequipmen.modelName == 'uv200'"
                  src="../../../../assets/imgs/shebei_img2.png"
                />
                <img
                  class="imgcard_a"
                  v-if="devequipmen.modelName == 'uv400'"
                  src="../../../../assets/imgs/shebei_img2.png"
                />
                <img
                  class="displayimg"
                  :src="
                    devequipmen.status
                      ? require('@/assets/imgs/zaixian.png')
                      : require('@/assets/imgs/zaixian1.png')
                  "
                />
              </div>
              <div class="eqcontent">
                <div class="deviceName">{{ devequipmen.name }}</div>
                <p>SN:{{ devequipmen.mid | mids }}</p>
                <p>
                  <span style="margin-right: 0.15rem"
                    >{{ $t("lang.hardwareVersion") }}：{{
                      devequipmen.hardVer ? devequipmen.hardVer : "--"
                    }}</span
                  >
                  <span
                    >{{ $t("lang.softwareVersion") }}：{{
                      devequipmen.softVer ? devequipmen.softVer : "--"
                    }}</span
                  >
                </p>
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <!-- 直播、录播开启 -->
              <div
                v-if="
                  devequipmen.deviceRealtimeInfoDto &&
                  devequipmen.deviceRealtimeInfoDto.workModeStatus &&
                  devequipmen.deviceStatus &&
                  devequipmen.deviceStatus.runStatus !== 8
                "
              >
                <div
                  class="startlive"
                  @click="liveclik(devequipmen)"
                  :class="
                    devequipmen.deviceRealtimeInfoDto.workModeStatus
                      .workStatus === 1
                      ? 'startactive'
                      : ''
                  "
                  v-if="devequipmen.deviceStatus.runStatus !== 5"
                >
                  <div
                    v-if="
                      devequipmen.deviceRealtimeInfoDto.workModeStatus
                        .workMode === 0
                    "
                  >
                    <span
                      v-if="
                        devequipmen.deviceRealtimeInfoDto.workModeStatus
                          .workStatus == 0
                      "
                      >{{ $t("lang.Clickstart") }}</span
                    >
                    <span
                      v-if="
                        devequipmen.deviceRealtimeInfoDto.workModeStatus
                          .workStatus == 1
                      "
                      >{{ $t("lang.Clickend") }}</span
                    >
                  </div>
                  <div
                    v-if="
                      devequipmen.deviceRealtimeInfoDto.workModeStatus
                        .workMode === 1
                    "
                  >
                    <span
                      v-if="
                        devequipmen.deviceRealtimeInfoDto.workModeStatus
                          .workStatus == 0
                      "
                      >{{ $t("lang.Clickstarts") }}</span
                    >
                    <span
                      v-if="
                        devequipmen.deviceRealtimeInfoDto.workModeStatus
                          .workStatus == 1
                      "
                      >{{ $t("lang.Clickends") }}</span
                    >
                  </div>
                  <div style="font-size: 0.25rem">{{ livetime }}</div>
                </div>
                <div v-else class="startlives">
                  <img src="@/assets/imgs/devlis/wifi.png" />
                  <span>{{ $t("lang.Signals") }}</span>
                </div>
              </div>
              <div
                class="startlives"
                v-if="
                  devequipmen.deviceStatus &&
                  devequipmen.deviceStatus.runStatus == 8
                "
              >
                <span>{{ $t("lang.Booting") }}</span>
              </div>
              <div class="startlives" v-if="!devequipmen.status">
                <span>{{ $t("lang.Signal") }}</span>
              </div>
              <div class="funt">
                <div
                  v-if="
                    devequipmen.deviceRealtimeInfoDto &&
                    devequipmen.deviceRealtimeInfoDto.workModeStatus &&
                    devequipmen.deviceConfigDto
                  "
                >
                  <div
                    v-if="
                      devequipmen.deviceRealtimeInfoDto.workModeStatus
                        .workMode === 0
                    "
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('lang.Startrecording')"
                      placement="top"
                      v-if="devequipmen.deviceConfigDto.recordStatus == 0"
                    >
                      <img
                        src="@/assets/imgs/devlis/shoul1.png"
                        @click="handleStartRecord(devequipmen, 1)"
                      />
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('lang.endrecording')"
                      placement="top"
                      v-if="devequipmen.deviceConfigDto.recordStatus == 1"
                    >
                      <img
                        src="@/assets/imgs/devlis/shoul2.png"
                        @click="handleStartRecord(devequipmen, 0)"
                      />
                    </el-tooltip>
                  </div>
                </div>
                <span
                  v-if="
                    devequipmen.deviceRealtimeInfoDto &&
                    devequipmen.deviceRealtimeInfoDto.workModeStatus
                  "
                >
                  <span
                    class="funts"
                    v-if="
                      devequipmen.deviceRealtimeInfoDto.workModeStatus
                        .workMode === 0
                    "
                  ></span>
                </span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('lang.setup')"
                  placement="top"
                >
                  <img
                    @click="handleSetDevice(devequipmen)"
                    src="@/assets/imgs/shezhi.png"
                  />
                </el-tooltip>
                <span class="funts"></span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('lang.details')"
                  placement="top"
                >
                  <img
                    @click="handleDetail(devequipmen)"
                    src="@/assets/imgs/xiangqing.png"
                  />
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="devxx_left">
            <div class="msgleft">
              <div class="msgone">
                <img src="@/assets/imgs/001.png" />
                <p v-if="devequipmen.status">
                  <span>{{ $t("lang.transmissionRate") }}：</span>
                  <span
                    v-if="
                      devequipmen.deviceRealtimeInfoDto &&
                      devequipmen.deviceRealtimeInfoDto.recvRate
                    "
                  >
                    {{ devequipmen.deviceRealtimeInfoDto.recvRate }} </span
                  >kbps
                </p>
                <p v-else>
                  <span>{{ $t("lang.transmissionRate") }}：</span>
                  <span>--</span>
                </p>
              </div>
              <div class="msgone" v-if="devequipmen.status">
                <img src="@/assets/imgs/002.png" />
                <p>
                  {{ $t("lang.codeRate") }}：{{ devequipmen.bitrateEncKb }}kbps
                </p>
              </div>
              <div class="msgone" v-else>
                <img src="@/assets/imgs/002.png" />
                <p>{{ $t("lang.codeRate") }}：--</p>
              </div>
              <div class="msgone">
                <img src="@/assets/imgs/003.png" />
                <p v-if="devequipmen.status">
                  <span>{{ $t("lang.inputSystem") }}：</span>
                  <span>{{
                    devequipmen.deviceRealtimeInfoDto.inputFmtStr
                  }}</span>
                </p>
                <p v-else>
                  <span>{{ $t("lang.inputSystem") }}：</span>
                  <span>--</span>
                </p>
              </div>
              <div class="msgone">
                <img src="@/assets/imgs/source.png" />
                <p v-if="devequipmen.status">
                  <span>{{ $t("lang.Inputinterface") }}：</span>
                  <span>{{
                    devequipmen.deviceRealtimeInfoDto.inputSrcStr
                  }}</span>
                </p>
                <p v-else>
                  <span>{{ $t("lang.Inputinterface") }}：</span>
                  <span>--</span>
                </p>
              </div>
              <!-- <div>
								<progress-charts :chartsName="'progressCharts_0'" :value="(devequipmen.storageTotal - devequipmen.storageUsed)" 
								:total="Number(devequipmen.storageTotal)"></progress-charts>
							</div> -->
              <div
                class="msgone"
                style="margin-bottom: 0.15rem"
                v-if="devequipmen.status"
              >
                <img src="@/assets/imgs/devlis/VBR.png" />
                <span
                  >{{ $t("lang.codingAlgorithm") }}：{{
                    devequipmen.deviceConfigDto.encodeType ? "H.265" : "H.264"
                  }}</span
                >
              </div>
              <div class="msgone" style="margin-bottom: 0.15rem" v-else>
                <img src="@/assets/imgs/devlis/VBR.png" />
                <span>{{ $t("lang.codingAlgorithm") }}：--</span>
              </div>
              <div class="msgone" v-if="devequipmen.status">
                <img style="width: 0.22rem" src="@/assets/imgs/devlis/HD.png" />
                <span
                  >{{ $t("lang.Bitratemode") }}：{{
                    devequipmen.deviceConfigDto.videoCbr ? "CBR" : "VBR"
                  }}</span
                >
              </div>
              <div class="msgone" v-else>
                <img style="width: 0.22rem" src="@/assets/imgs/devlis/HD.png" />
                <span>{{ $t("lang.Bitratemode") }}：--</span>
              </div>
              <div v-if="devequipmen.status" style="margin-bottom: 0.15rem">
                <span>{{ $t("lang.diskSpace") }}：</span>
                <span style="font-size: 0.14rem; display: inline-block">
                  <span
                    >{{
                      (
                        (devequipmen.storageTotal - devequipmen.storageUsed) /
                        1024 /
                        1024
                      ).toFixed(2)
                    }}GB{{ $t("lang.available") }}</span
                  >
                  <span style="display: inline-block; margin-left: 0.03rem"
                    >/ {{ $t("lang.Total")
                    }}{{
                      (devequipmen.storageTotal / 1024 / 1024).toFixed(2)
                    }}GB</span
                  >
                </span>
              </div>
              <div style="margin-bottom: 0.15rem" v-else>
                <span>{{ $t("lang.diskSpace") }}：--</span>
              </div>
              <div v-if="devequipmen.status">
                <div
                  class="statemsg"
                  v-if="
                    devequipmen.battery &&
                    devequipmen.deviceStatus &&
                    devequipmen.deviceStatus.batteryStatus != 2
                  "
                >
                  <span
                    class="statemsg_a"
                    :class="
                      devequipmen.battery > 0 && devequipmen.battery < 25
                        ? 'statemsg_b'
                        : ''
                    "
                  >
                    <div
                      class="state"
                      :style="{ width: batterys }"
                      :class="
                        devequipmen.battery > 0 && devequipmen.battery < 25
                          ? 'states'
                          : ''
                      "
                    ></div>
                    <img
                      v-if="devequipmen.deviceStatus.batteryStatus == 1"
                      src="@/assets/imgs/devlis/chond.png"
                    />
                  </span>
                  <div
                    class="state_a"
                    :class="
                      devequipmen.battery > 0 && devequipmen.battery < 25
                        ? 'state_b'
                        : ''
                    "
                  ></div>
                  <p style="margin-left: 0.08rem">
                    {{ devequipmen.battery ? devequipmen.battery : 0 }}%
                  </p>
                </div>
                <div
                  v-if="devequipmen.deviceStatus.batteryStatus == 2"
                  style="margin-top: 0.15rem"
                >
                  <img src="@/assets/imgs/devlis/notbit.png" />
                </div>
              </div>
              <div v-else>
                <div class="statemsg" style="width: 2rem">
                  <span class="statemsg_a">
                    <div class="state" :style="{ width: batterys }"></div>
                  </span>
                  <div class="state_a"></div>
                </div>
              </div>
            </div>
            <div class="msgleft">
              <div class="msgone">
                <!-- <img src="@/assets/imgs/001.png" /> -->
                <p v-if="devequipmen.deviceConfigDto">
                  <span>{{ $t("lang.resolvingpower") }}：</span>
                  <span>
                    {{
                      devequipmen.deviceConfigDto.videoEncodeResolution == 1
                        ? "1920x1080"
                        : devequipmen.deviceConfigDto.videoEncodeResolution == 2
                        ? "1280x720"
                        : devequipmen.deviceConfigDto.videoEncodeResolution == 3
                        ? "720x576"
                        : devequipmen.deviceConfigDto.videoEncodeResolution == 4
                        ? "720*480"
                        : $t("lang.automatic")
                    }}
                    <!-- @{{
                      devequipmen.deviceConfigDto.videoEncodeFramerate == 1
                        ? "25fps"
                        : devequipmen.deviceConfigDto.videoEncodeFramerate == 2
                        ? "29.97fps"
                        : devequipmen.deviceConfigDto.videoEncodeFramerate == 3
                        ? "30fps"
                        : devequipmen.deviceConfigDto.videoEncodeFramerate == 4
                        ? "50fps"
                        : devequipmen.deviceConfigDto.videoEncodeFramerate == 5
                        ? "59fps"
                        : devequipmen.deviceConfigDto.videoEncodeFramerate == 6
                        ? "60fps"
                        : "auto"
                    }} -->
                  </span>
                </p>
                <p v-else>
                  <span>{{ $t("lang.Framerate") }}：</span>
                  <span>--</span>
                </p>
              </div>
              <div class="msgone">
                <!-- <img src="@/assets/imgs/001.png" /> -->
                <p v-if="devequipmen.deviceConfigDto">
                  <span>{{ $t("lang.Framerate") }}：</span>
                  <span>
                    <!-- {{
                      devequipmen.deviceConfigDto.videoEncodeResolution == 1
                        ? "1920x1080"
                        : devequipmen.deviceConfigDto.videoEncodeResolution == 2
                        ? "1280x720"
                        : devequipmen.deviceConfigDto.videoEncodeResolution == 3
                        ? "720x576"
                        : devequipmen.deviceConfigDto.videoEncodeResolution == 4
                        ? "720*480"
                        : $t("lang.automatic")
                    }}@ -->
                    {{
                      devequipmen.deviceConfigDto.videoEncodeFramerate == 1
                        ? "25fps"
                        : devequipmen.deviceConfigDto.videoEncodeFramerate == 2
                        ? "29.97fps"
                        : devequipmen.deviceConfigDto.videoEncodeFramerate == 3
                        ? "30fps"
                        : devequipmen.deviceConfigDto.videoEncodeFramerate == 4
                        ? "50fps"
                        : devequipmen.deviceConfigDto.videoEncodeFramerate == 5
                        ? "59fps"
                        : devequipmen.deviceConfigDto.videoEncodeFramerate == 6
                        ? "60fps"
                        : "auto"
                    }}
                  </span>
                </p>
                <p v-else>
                  <span>{{ $t("lang.Framerate") }}：</span>
                  <span>--</span>
                </p>
              </div>
              <div class="msgone">
                <!-- <img src="@/assets/imgs/003.png" /> -->
                <p v-if="devequipmen.deviceConfigDto">
                  <span>{{ $t("lang.Rotationangle") }}：</span>
                  <span>{{
                    devequipmen.deviceConfigDto.videoEncodeRotation == 1
                      ? $t("lang.Rotateone")
                      : devequipmen.deviceConfigDto.videoEncodeRotation == 2
                      ? $t("lang.Rotatetwo")
                      : devequipmen.deviceConfigDto.videoEncodeRotation == 3
                      ? $t("lang.Rotatether")
                      : $t("lang.Norotation")
                  }}</span>
                </p>
                <p v-else>
                  <span>{{ $t("lang.Rotationangle") }}：</span>
                  <span>--</span>
                </p>
              </div>
              <!-- <div class="msgone">
                <p v-if="devequipmen.deviceConfigDto">
                  <span>{{ $t("lang.deinterlacing") }}：</span>
                  <span>{{
                    devequipmen.deviceConfigDto.videoEncodeDeinterlacing == 1
                      ? $t("lang.open")
                      : $t("lang.close")
                  }}</span>
                </p>
                <p v-else>
                  <span>{{ $t("lang.deinterlacing") }}：</span>
                  <span>--</span>
                </p>
              </div> -->
              <div
                class="msgone"
                style="margin-bottom: 0.15rem"
                v-if="devequipmen.deviceConfigDto"
              >
                <!-- <img src="@/assets/imgs/devlis/VBR.png" /> -->
                <span
                  >{{ $t("lang.Audiomode") }}：{{
                    devequipmen.deviceConfigDto.audioEncodeMode == 1
                      ? "AAC-HE"
                      : "AAC-LC"
                  }}</span
                >
              </div>
              <div class="msgone" style="margin-bottom: 0.15rem" v-else>
                <!-- <img src="@/assets/imgs/devlis/VBR.png" /> -->
                <span>{{ $t("lang.Audiomode") }}：--</span>
              </div>
              <div class="msgone" v-if="devequipmen.deviceConfigDto">
                <!-- <img style="width: .22rem;" src="@/assets/imgs/devlis/HD.png" /> -->
                <span
                  >{{ $t("lang.Audiobitrate") }}：{{
                    devequipmen.deviceConfigDto.audioEncodeBitrate
                  }}Kb</span
                >
              </div>
              <div class="msgone" v-else>
                <!-- <img style="width: .22rem;" src="@/assets/imgs/devlis/HD.png" /> -->
                <span>{{ $t("lang.Audiobitrate") }}：--</span>
              </div>
              <div class="msgone" v-if="devequipmen.deviceConfigDto">
                <!-- <img style="width: .22rem;" src="@/assets/imgs/devlis/HD.png" /> -->
                <span
                  >{{ $t("lang.Audiosamplingrate") }}：{{
                    devequipmen.deviceConfigDto.audioEncodeSamplerate == 1
                      ? "44100"
                      : "48000"
                  }}</span
                >
              </div>
              <div class="msgone" v-else>
                <!-- <img style="width: .22rem;" src="@/assets/imgs/devlis/HD.png" /> -->
                <span>{{ $t("lang.Audiosamplingrate") }}：--</span>
              </div>
            </div>
            <div class="msgright">
              <div>
                <!-- <div>{{ $t("lang.bitRate") }}:</div> -->
                <div v-if="devequipmen.deviceConfigDto">
                  <div
                    style="
                      width: 3.2rem;
                      height: 0.86rem;
                      padding: 0.1rem 0.1rem 0;
                      box-sizing: border-box;
                    "
                  >
                    <span>{{ $t("lang.bitRate") }}：</span>
                    <div class="cardtap" v-if="bitesa">
                      <el-input
                        v-model="bitess"
                        :maxlength="5"
                        autofocus="autofocus"
                        @blur="bitbl"
                        @keyup.enter.native="bitbls"
                        ref="bitinput"
                      ></el-input>
                    </div>
                    <div
                      v-else
                      class="cardtap"
                      style="color: #000000"
                      @click="bits(devequipmen)"
                    >
                      {{ devequipmen.deviceConfigDto.videoBitrate }}kb/s
                    </div>
                    <el-slider
                      v-model="devequipmen.deviceConfigDto.videoBitrate"
                      ref="slider1"
                      :min="100"
                      :max="15360"
                      :show-tooltip="false"
                      @change="changeList($event, devequipmen)"
                      @input="inps"
                    ></el-slider>
                  </div>
                </div>
                <div class="speedtime">
                  <span>100kb/s</span>
                  <span>15Mb/s</span>
                </div>
              </div>
              <div style="margin-top: 0.2rem">
                <!-- <div>{{ $t("lang.delayed") }}:</div> -->
                <div class="progressbar" v-if="devequipmen.deviceConfigDto">
                  <div
                    style="
                      width: 3.2rem;
                      height: 0.86rem;
                      padding: 0.1rem 0.1rem 0;
                      box-sizing: border-box;
                    "
                  >
                    <span>{{ $t("lang.delayed") }}：</span>
                    <div class="cardtap" v-if="cachesa">
                      <el-input
                        v-model="cachess"
                        :maxlength="5"
                        :min="500"
                        autofocus="autofocus"
                        @blur="cachbl"
                        @keyup.enter.native="cachbls"
                        ref="carinput"
                      ></el-input>
                    </div>
                    <div
                      v-else
                      class="cardtap"
                      style="color: #000000"
                      @click="cars(devequipmen)"
                    >
                      {{ devequipmen.deviceConfigDto.cache }}ms
                    </div>
                    <el-slider
                      v-model="devequipmen.deviceConfigDto.cache"
                      :show-tooltip="false"
                      :min="0"
                      :max="30000"
                      @change="changeListTwo($event, devequipmen)"
                      @input="inpsa"
                    ></el-slider>
                  </div>
                </div>
                <!-- <div class="progressbar" v-if="devequipmen.deviceDefaults && !devequipmen.status">
								  <div style="width: 3.2rem;height: 0.86rem;padding: 0.1rem 0.1rem 0;box-sizing: border-box;">
										<div class="cardtap" v-if="cachesa">
											<el-input v-model="caches" :maxlength='5' ref="cacinputs"></el-input>
										</div>
										<div  v-else class="cardtap" style="color: #000000;" @click="cars">{{ devequipmen.deviceDefaults.cache }}ms</div>
								    <el-slider v-model="devequipmen.deviceDefaults.cache" ref="slider2" :show-tooltip="false" :min="0" :max="30000"
								      @change="changeListTwo($event, devequipmen)"></el-slider>
								  </div>
								</div> -->
                <div class="speedtime">
                  <span>0ms</span>
                  <span>30000ms</span>
                </div>
              </div>
            </div>
          </div>
          <div class="urlleft">
            <div class="url" style="margin-left: 0; width: 100%">
              <p>{{ $t("lang.streamingAddress") }}:</p>
              <button
                @click="handleImportPlan(devequipmen, devequipmen.steamAddr)"
              >
                {{ $t("lang.importScheme") }}
              </button>
              <div class="lines"></div>
            </div>
            <div
              v-if="
                devequipmen.deviceRealtimeInfoDto &&
                devequipmen.deviceRealtimeInfoDto.pushstreamout
              "
            >
              <div class="showname">
                <div
                  v-for="(obj, i) in devequipmen.deviceRealtimeInfoDto
                    .pushstreamout"
                  :key="i"
                  class="shownames"
                >
                  <div class="over-name">{{ obj.url }}</div>
                  <div class="over-names" @click="fuzhiUrl(obj.url)">
                    {{ $t("lang.copy") }}
                  </div>
                  <div class="showname_a">
                    <div>
                      <span>{{ $t("lang.state") }}：</span>
                      <span v-if="obj.stat == 0">{{ $t("lang.free") }}</span>
                      <span v-if="obj.stat == 1">{{ $t("lang.free_a") }}</span>
                      <span v-if="obj.stat == 2">{{ $t("lang.free_b") }}</span>
                      <span v-if="obj.stat == 3">{{ $t("lang.free_c") }}</span>
                      <span v-if="obj.stat == 4">{{ $t("lang.free_d") }}</span>
                    </div>
                    <img
                      :src="require('../../../../assets/imgs/del_icon.png')"
                      alt
                      style="width: 0.28rem"
                      @click="
                        handleDelUrl(
                          devequipmen,
                          devequipmen.deviceRealtimeInfoDto.pushstreamout,
                          i,
                          devequipmen.steamAddr
                        )
                      "
                    />
                  </div>
                </div>
              </div>
              <img
                style="width: 0.32rem; height: 0.32rem; margin-top: 0.2rem"
                src="../../../../assets/imgs/tainjia.png"
                alt
                @click="
                  addPushName(
                    devequipmen,
                    devequipmen.deviceConfigDto.streamout,
                    devequipmen.steamAddr
                  )
                "
                v-if="
                  devequipmen.deviceRealtimeInfoDto.pushstreamout.length < 4
                "
              />
            </div>
          </div>
        </div>
        <div class="equimentheader_b">
          <div class="devxx_right">
            <div class="devxx_rights">
              <div
                class="devxx_video"
                @click="
                  videos(devequipmen.mid, devequipmen, devequipmen.steamAddr)
                "
                ref="video_a"
              >
                <img src="../../../../assets/imgs/video.png" />
              </div>
              <video
                id="video-webrtc"
                muted
                autoplay
                controls
                ref="movie"
              ></video>
            </div>
          </div>
          <!-- 折线图 -->
          <div style="width: 5.6rem; margin-top: 0.15rem">
            <div
              id="mainsa"
              style="width: 99%; height: 210px; background-color: #222628"
            ></div>
          </div>
          <div
            class="url"
            style="margin-top: 0.2rem; margin-left: 0; width: 100%"
          >
            <p>{{ $t("lang.Pullflowaddress") }}:</p>
            <div class="lines"></div>
          </div>
          <div class="switchs">
            <div
              class="switce_ad"
              v-for="(item, index) in devequipmen.steamAddr"
              :key="index"
            >
              <el-switch
                v-model="item.isfor"
                active-color="#307b37"
                inactive-color="#474747"
                @change="
                  switceChangeOne(
                    $event,
                    devequipmen.mid,
                    devequipmen,
                    devequipmen.steamAddr,
                    item
                  )
                "
              >
              </el-switch>
              <span class="titles" v-if="item.type == 2">RTMP</span>
              <span class="titles" v-else>HLS</span>
              <span class="fizhi" @click="fuzhiUrl(item.url)">{{
                $t("lang.copy")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="urlright">
        <div
          class="bitmsg"
          v-for="(obj, i) in devequipmen.linkInfo"
          :key="i"
          @contextmenu.prevent="rightClick($event, i,obj.usb_index)"
        >
          <div
            :id="'menu' + i"
            class="menulsit"
            v-show="visibles && activeIndex === i"
          >
            <ul>
              <li @click="editDeviceLinkCtrlApi(obj, 1)">{{ $t("lang.SetRouting") }}</li>
              <li
                @click="editDeviceLinkCtrlApi(obj, 0)"
                v-if="obj.type != 1 && obj.type != 0"
              >
              {{ $t("lang.restart") }}
              </li>
              <li class="lis" v-if="obj.type != 1 && obj.type != 0">
                <span class="spans">
                  {{ $t("lang.Networkmode") }}
                  <img
                    class="imgadsright"
                    src="../../../../assets/imgs/rightimg.png"
                    alt=""
                  />
                </span>
                <ul class="menuls" v-if="obj.type != 1 && obj.type != 0">
                  <li @click="tonumbers(obj, 0)">{{ $t("lang.automatic") }}</li>
                  <!-- <li @click="tonumbers(obj, 2)">2G</li> -->
                  <li @click="tonumbers(obj, 3)" :style="obj.type === 5?'color:#307b37':''">3G</li>
                  <li @click="tonumbers(obj, 4)" :style="obj.type === 2?'color:#307b37;font-weight: bold;':''">4G</li>
                  <!-- <li @click="tonumbers(obj, 5)">5G</li> -->
                </ul>
              </li>
            </ul>
          </div>
          <div class="bitrate-item">
            <div style="display: flex;z-index: 9999 !important;">
              <el-switch
                v-model="obj.enable"
                active-color="#307b37"
                inactive-color="#474747"
                @change="listchange(obj, i)"
              >
              </el-switch>
              <div
                :class="obj.enable ? 'orangeColor' : ''"
                style="
                  font-size: 0.13rem;
                  margin-right: 0.1rem;
                  margin-left: 0.1rem;
                "
              >
                <!-- <span v-if="obj.type === 0">{{ obj.isp }}</span>
                <span v-if="obj.type === 1">wifi</span>
                <span v-if="obj.type === 2">2G</span>
                <span v-if="obj.type === 3">3G</span>
                <span v-if="obj.type === 4">4G</span>
                <span v-if="obj.type === 5">5G</span> -->
                <span v-if="obj.type === 0">{{ obj.isp }}</span>
                <span v-if="obj.type === 1">wifi</span>
                <span v-if="obj.type === 2">4G</span>
                <span v-if="obj.type === 3">5G</span>
                <span v-if="obj.type === 4">2G</span>
                <span v-if="obj.type === 5">3G</span>
              </div>
              <div class="xinhao1" style="margin-left: 0.08rem">
                <div>
                  <div
                    class="xinhao"
                    v-if="
                      (obj.type === 2 && obj.signal > 0) ||
                      (obj.type === 3 && obj.signal > 0)
                    "
                  >
                    <div v-if="obj.signal > 0"></div>
                    <div v-if="obj.signal > 1"></div>
                    <div v-if="obj.signal > 2"></div>
                    <div v-if="obj.signal == 4 || obj.signal > 4"></div>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-left: 0.05rem">
              <img
                v-if="obj.isp === 'MOBILE'"
                style="width: 0.23rem; height: 0.26rem"
                :style="!obj.enable?'width: 15px;height: 17px;':''"
                :src="
                  !obj.enable
                    ? require('@/assets/imgs/sim.png')
                    : require('@/assets/imgs/yidong.png')
                "
              />
              <img
                v-if="obj.isp === 'UNICOM'"
                style="width: 0.25rem; height: 0.28rem"
                :src="
                  !obj.enable
                    ? require('@/assets/imgs/liantong2.png')
                    : require('@/assets/imgs/liantong.png')
                "
              />
              <img
                v-if="obj.isp === 'TELECOM'"
                style="width: 0.24rem; height: 0.28rem"
                :src="
                  !obj.enable
                    ? require('@/assets/imgs/dainxin2.png')
                    : require('@/assets/imgs/dainxin.png')
                "
              />
              <img
                v-if="obj.isp === 'wifi'"
                style="width: 0.25rem; height: 0.19rem"
                :src="
                  !obj.enable
                    ? require('@/assets/imgs/wifi2.png')
                    : require('@/assets/imgs/wifi.png')
                "
              />
              <img
                v-if="obj.isp === 'eth'"
                style="width: 0.25rem; height: 0.21rem"
                :src="
                  !obj.enable
                    ? require('@/assets/imgs/wangka2.png')
                    : require('@/assets/imgs/wangka.png')
                "
              />
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              color: #195d94;
            "
            :class="obj.enable ? 'orangeColor' : ''"
          >
            <div :id="'echasads' + i" style="width: 500px; height: 330px;top:0px"></div>
            <!-- <div style="margin-right: 0.1rem; font-size: 0.12rem">BitRate</div>
            <div style="font-size: 0.12rem">
              {{
                parseInt((obj.recvRate[obj.recvRate.length - 1] * 8) / 1024)
              }}Kbps
            </div> -->
          </div>
        </div>

        <div
          class="bitmsg"
          v-if="
            devequipmen.linkInfo &&
            (devequipmen.linkInfo.length == 4 ||
              devequipmen.linkInfo.length == 7 ||
              devequipmen.linkInfo.length == 10 ||
              devequipmen.linkInfo.length == 13)
          "
          style="background: none"
        ></div>
        <div
          class="bitmsg"
          v-if="
            devequipmen.linkInfo &&
            devequipmen.linkInfo.length != 3 &&
            devequipmen.linkInfo.length != 6 &&
            devequipmen.linkInfo.length !== 9 &&
            devequipmen.linkInfo.length != 12 &&
            devequipmen.linkInfo.length != 15
          "
          style="background: none"
        ></div>
      </div>
    </div>
    <div v-else class="zwdev">{{ $t("lang.Noequipment") }}</div>
    <!-- 设置设备参数 -->
    <set-device
      ref="setDevice"
      @refreshDeviceMsg="refreshDeviceMsg"
    ></set-device>
    <!-- 删除设备 -->
    <del-device
      ref="delDevice"
      @refreshDeviceMsg="refreshDeviceMsg"
    ></del-device>
    <!-- 导入方案 -->
    <import-plan
      ref="importPlan"
      @refreshDeviceMsg="refreshDeviceMsg"
    ></import-plan>
    <!-- 添加地址 -->
    <add-furl ref="addFurl" @refreshDeviceMsg="refreshDeviceMsg"></add-furl>
    <!-- v-if="isShows" -->
    <div class="modals" v-if="isShows">
      <div class="modals_posi">
        <div class="modals_center">
          <div class="modals_top">{{ $t("lang.Tips") }}</div>
          <div class="modals_img">
            <img src="../../../../assets/imgs/iphones.png" alt />
          </div>
          <div class="modals_text">{{ $t("lang.Thisaccount") }}</div>
          <div class="checkboxs">
            <el-checkbox v-model="checked" @change="changeClick">{{
              $t("lang.Nextlogin")
            }}</el-checkbox>
          </div>
          <div class="modals_bottom">
            <div class="allcl modals_bottom_remove" @click="clickRemove">
              {{ $t("lang.cancel") }}
            </div>
            <div class="allcl modals_bottom_op" @click="clickOk">
              {{ $t("lang.bindings") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="devopenzb"
      v-if="
        devequipmen.deviceRealtimeInfoDto &&
        devequipmen.deviceRealtimeInfoDto.workModeStatus
      "
    >
      <el-dialog
        :visible.sync="dialogs"
        width="5.47rem"
        top="2rem"
        :title="titles"
      >
        <div v-if="switchs" style="font-size: 0.16rem; text-align: center">
          <span>{{ $t("lang.Areyousure") }}</span>
          <span
            v-if="
              devequipmen.deviceRealtimeInfoDto.workModeStatus.workMode === 0
            "
            >{{ $t("lang.Turnonlives") }}{{ $t("lang.Isthatright") }}？</span
          >
          <span v-else
            >{{ $t("lang.Startrecordingsa")
            }}{{ $t("lang.Isthatright") }}？</span
          >
        </div>
        <div v-else class="devzb">
          <label
            v-if="
              devequipmen.deviceRealtimeInfoDto.workModeStatus.workMode === 0
            "
            >{{ $t("lang.Livename") }}：</label
          >
          <label v-else>{{ $t("lang.Recordingname") }}：</label>
          <el-input
            v-model="opens.workName"
            maxlength="40"
            style="width: 65%"
          ></el-input>
        </div>
        <span class="dialog-footer">
          <button @click="dialogs = false">
            {{ $t("lang.cancel") }}
          </button>
          <button v-if="switchs" @click="submitForm(0)">
            {{ $t("lang.confirm") }}
          </button>
          <button v-else @click="submitForm(1)">
            {{ $t("lang.confirm") }}
          </button>
        </span>
      </el-dialog>
    </div>
    <div class="arrears">
      <el-dialog
        :visible.sync="dialogarrars"
        width="4.18rem"
        top="2rem"
        :title="arrertitle"
      >
        <div class="tipse">
          <div v-if="monys === 1">{{ $t("lang.balanceisinsufficient") }}</div>
          <div v-if="monys === 2">{{ $t("lang.accountarrears") }}</div>
          <div v-if="monys === 3">
            {{ $t("lang.Currentaccount") }}
            <span style="font-size: 0.18rem; font-weight: bold">{{
              balanas
            }}</span>
            {{ $t("lang.Pleaserecharge") }}
          </div>
        </div>
        <span class="dialog-footer">
          <button @click="dialogarrars = false">{{ $t("lang.close") }}</button>
          <button @click="toRecharge">{{ $t("lang.Rechargenow") }}</button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import * as Apis from "@/api/apis";
import * as equipmentApis from "@/api/equipment";
// import batteryCharts from "./components/echarts/battery";
// import progressCharts from "../../../../components/echarts/progress";
// import progressBarCharts from "../../../../components/echarts/progressBar";
import addFurl from "./components/addUrl";
import importPlan from "./components/importPlan";
//import addDevice from "./components/addDevice";
import delDevice from "./components/delDevice";
import setDevice from "./components/setDevice";
import bus from "@/utils/bus.js";
//import { time } from "echarts";
import * as tiemsa from "@/utils/time";
import * as userApi from "@/api/apis";
import { JSWebrtc } from "./components/video/jswebrtc.js";
import { integersa, inttext } from "@/utils/time";
import { json } from "body-parser";
export default {
  components: {
    // batteryCharts,
    // progressCharts,
    // progressBarCharts,
    addFurl,
    importPlan,
    //addDevice,
    delDevice,
    setDevice,
  },
  filters: {
    formatTime(value) {
      //console.log(value)
      let hr = parseInt((value / 60 / 60) % 24);
      let min = parseInt((value / 60) % 60);
      let sec = parseInt(value % 60);

      hr = hr > 9 ? hr : "0" + hr;
      min = min > 9 ? min : "0" + min;
      sec = sec > 9 ? sec : "0" + sec;
      return `${hr}:${min}:${sec}`;
    },
    mids(val) {
      let sk = "";
      if (val) {
        sk = val.slice(16);
      }
      return sk;
    },
  },
  data() {
    return {
      start: true,
      eqlist: [],
      apiTimer: null,
      checked: false,
      deviceTimer: null,
      isShows: false,
      batteryTips: this.$t("lang.electricitySurplus"),
      recordTime: [],
      marks: {
        0: "0°C",
        100: "37°C",
      },
      livetime: "00:00:00",
      livetimer: null,
      url: "",
      userxx: {},
      devequipmen: {},
      getdev: null,
      filgs: false,
      date: [],
      dase: [],
      charttimell: null,
      chartimes: null,
      dialogs: false,
      titles: "",
      opens: {
        workName: "",
      },
      myChart: "",
      devnone: false,
      videhttp: "",
      default: [
        20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1,
      ],
      defaults: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      player: "",
      myset: {
        mad: "",
        hcho: "",
      },
      cosnts: 0,
      cosntsa: 0,
      tabekbs: "",
      batterys: "0%",
      cachesa: false,
      cachess: "",
      caches: "",
      bitess: "",
      bitesa: false,
      switchs: false,
      switchdata: {},
      dialogarrars: false,
      monys: 0,
      arrertitle: "",
      balanas: "",
      visibles: false,
      activeIndex: 200,
    };
  },
  created() {
    let surl = window.location.href;
    let sker = surl.indexOf("nav");
    let dk = surl.slice(0, sker);
    this.videhttp = "static/indx.html";
    //console.log(dk)
    this.userxx = JSON.parse(sessionStorage.getItem("user"));
    this.getsea();
    this.getdev = setInterval(() => {
      this.getsea();
    }, 1000);
    bus.$on("langchange", () => {
      this.batteryTips = this.$t("lang.electricitySurplus");
    });
  },
  mounted() {
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.apiTimer);
    });
    if (!sessionStorage.getItem("getBindPhoneStatus")) {
      this.getBindPhoneStatus();
    }
    this.chars();
    this.charttimell = setInterval(() => {
      if (this.myChart) {
        if (this.devequipmen.Linechart) {
          let sk = 0;
          this.devequipmen.Linechart.forEach((item) => {
            sk = sk + parseInt(item);
          });
          this.tabekbs = (sk / 20).toFixed(2) + " kbps";
        } else {
          this.tabekbs = "0 kbps";
        }
        //console.log(this.tabekbs)
        const opt = this.myChart.getOption();
        opt.title[0].subtext = this.tabekbs;
        opt.series[0].data = this.devequipmen.Linechart
          ? this.devequipmen.Linechart
          : this.defaults;
        this.myChart.setOption(opt);
      }
    }, 1000);
    let swq = setInterval(() => {
      if (this.$refs.movie) {
        clearInterval(swq);
        this.$refs.movie.addEventListener("play", this.handlePlay);
        this.$refs.movie.addEventListener("pause", this.handpausse);
      }
    }, 100);
  },
  watch: {
    //初始化图表
    filgs() {
      this.$nextTick(() => {
        //console.log(this.devequipmen)
        if (this.devequipmen) {
        } else {
          this.myChart.dispose();
        }
      });
    },
    $route() {
      this.$nextTick(() => {
        this.$refs.video_a.style.zIndex = "100";
        this.cosnts = 0;
        this.cosntsa = 0;
        //this.myChart.dispose()
        //console.log(this.player.paused)
        if (this.player.paused == false) {
          this.handpausse();
        } else if (this.player.paused === true) {
          this.handpausse();
          this.$refs.video_a.style.zIndex = "100";
        }
      });
    },
    visibles(newValue, oldValue) {
      if (newValue) {
        //菜单显示的时候
        // document.body.addEventListener，document.body.removeEventListener它们都接受3个参数
        // ("事件名" , "事件处理函数" , "布尔值");
        // 在body上添加事件处理程序
        document.body.addEventListener("click", this.closeMenu);
      } else {
        //菜单隐藏的时候
        // 移除body上添加的事件处理程序
        document.body.removeEventListener("click", this.closeMenu);
      }
    },
  },
  methods: {
    listchange(item, index) {
      // console.log(item, index, this.$route.query.mid);
      let parms = {
        mid: this.$route.query.mid,
        dev: item.dev,
        enable: item.enable ? 1 : 0,
      };
      userApi.editDeviceLinkSet(parms).then((res) => {
        this.$message({
          message: this.$t("lang.Operations"),
          type: "success",
        });
      });
    },
    chars() {
      let newparosm = new Promise((resolve) => {
        resolve();
      });
      newparosm.then(() => {
        if (this.devequipmen.Linechart) {
          let sk = 0;
          this.devequipmen.Linechart.forEach((item) => {
            sk = sk + parseInt(item);
          });
          this.tabekbs = (sk / 20).toFixed(2) + " kbps";
        } else {
          this.tabekbs = "0 kbps";
        }
        let ske = setInterval(() => {
          if (document.getElementById("mainsa")) {
            clearInterval(ske);
            this.drawChart();
          }
        }, 100);
      });
    },
    //获取设备信息
    getsea() {
      //console.log('weww')

      let skr = JSON.parse(sessionStorage.getItem("devlist"));
      if (skr) {
        this.devnone = true;
        this.devequipmen = skr;

        if (
          this.devequipmen.deviceRealtimeInfoDto &&
          this.devequipmen.linkInfo
        ) {
          let arr = [];
          this.devequipmen.linkInfo.forEach((ims) => {
            if (ims.isp != "eth" && ims.isp != "wifi") {
              this.devequipmen.deviceRealtimeInfoDto.wwanLinkNetworkMode.forEach(
                (item, index) => {
                  if (item.link_index === ims.usb_index) {
                    this.devequipmen.deviceRealtimeInfoDto.wwanLinkNetworkMode.splice(index,1);
                  }
                }
              );
            }

            // console.log(this.devequipmen.deviceRealtimeInfoDto.wwanLinkNetworkMode)
          });
          console.log(this.devequipmen.deviceRealtimeInfoDto.wwanLinkNetworkMode)
          this.devequipmen.deviceRealtimeInfoDto.wwanLinkNetworkMode.forEach(
            (imsad) => {
              this.devequipmen.linkInfo.push({
                dev: imsad.mode,
                enable: 0,
                tabekbs:"0 kbps",
                isp: "MOBILE",
                recvRate: [
                  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                ],
                signal: "0",
                type:2,
                usb_index: imsad.link_index,
              });
            }
          );
          
        var arrOne = this.devequipmen.linkInfo.some(function(item) {
            if (item.isp == "eth") { //item.name == "小百里守约"
                return true;  //返回false
            }
        })
          if(!arrOne){
            arr.push(
              {
                dev: 0,
                enable: 0,
                isp: "eth",
                tabekbs:"0 kbps",
                recvRate: [
                  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                ],
                signal: "0",
                type:0,
                usb_index: 0,
              }
            )
          }
          var arrTwo = this.devequipmen.linkInfo.some(function(item) {
            if (item.isp == "wifi") { //item.name == "小百里守约"
                return true;  //返回false
            }
        })
          if(!arrTwo){
            arr.push(
              {
                dev: 0,
                enable: 0,
                isp: "wifi",
                tabekbs:"0 kbps",
                recvRate: [
                  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                ],
                signal: "0",
                type:1,
                usb_index: 0,
              }
            )
          }
          this.devequipmen.linkInfo = this.devequipmen.linkInfo.concat(arr);
        }
        if (this.devequipmen.linkInfo) {
          this.devequipmen.linkInfo.forEach((items) => {
            let ena = items.enable >= 1 ? true : false;
            items.enable = ena;
          });
        }

        if (
          this.devequipmen.deviceRealtimeInfoDto &&
          this.devequipmen.deviceRealtimeInfoDto.workModeStatus &&
          this.devequipmen.deviceConfigDto
        ) {
          let usertime =
            this.devequipmen.deviceRealtimeInfoDto.workModeStatus.workStatus;
          //获取直播、录播时间
          if (usertime === 1) {
            let sr =
              this.devequipmen.deviceRealtimeInfoDto.workModeStatus.startTime;
            let hr = parseInt((sr / 60 / 60) % 24);
            let min = parseInt((sr / 60) % 60);
            let sec = parseInt(sr % 60);
            hr = hr > 9 ? hr : "0" + hr;
            min = min > 9 ? min : "0" + min;
            sec = sec > 9 ? sec : "0" + sec;
            this.livetime = `${hr}:${min}:${sec}`;
          } else {
            this.livetime = "00:00:00";
          }
          if (
            this.devequipmen.deviceRealtimeInfoDto.workModeStatus.workMode ===
              0 &&
            this.devequipmen.deviceRealtimeInfoDto.workModeStatus.workStatus ===
              0
          ) {
            this.url = "";
            if (this.player) {
              this.handpausse();
            }
            if (this.$refs.video_a) {
              this.$refs.video_a.style.zIndex = "100";
            }
          }
          if (
            this.devequipmen.deviceRealtimeInfoDto.workModeStatus.workMode ===
              0 &&
            this.devequipmen.deviceRealtimeInfoDto.workModeStatus.workStatus ===
              0 &&
            this.devequipmen.deviceConfigDto.recordStatus === 1
          ) {
            this.handleStartRecords(this.devequipmen, 0);
          }
          if (
            this.devequipmen.deviceConfigDto &&
            this.devequipmen.deviceConfigDto.pullStream
          ) {
            let sewq = false;
            this.devequipmen.deviceConfigDto.pullStream.forEach((item) => {
              if (item.type === 3) {
                sewq = true;
              }
            });
            // debugger
            if (sewq == false) {
              this.videosa(this.devequipmen);
            }
          }
        }
        if (this.devequipmen.battery != null) {
          this.batterys = this.devequipmen.battery + "%";
        }
        if (!this.devequipmen.status) {
          this.url = "";
          if (this.$refs.video_a) {
            this.$refs.video_a.style.zIndex = "100";
          }
          if (this.player) {
            this.handpausse();
          }
        }
        this.cosnts = 0;
        this.cosntsa = 0;
        this.filgs = true;
        this.dlistChart();
      } else {
        this.filgs = false;
        this.devnone = false;
      }
    },
    inps(e) {
      if (this.devequipmen.status === 0) {
        return false;
      }
      //console.log(this.cosnts)
      this.cosnts++;
      if (this.cosnts > 2) {
        clearInterval(this.getdev);
      }
    },
    inpsa(e) {
      if (this.devequipmen.status === 0) {
        return false;
      }
      //console.log(this.cosntsa)
      this.cosntsa++;
      if (this.cosntsa > 2) {
        clearInterval(this.getdev);
      }
    },
    //处理预览问题
    async videosa(res) {
      //console.log(res)
      let arrs = [];
      if (res.steamAddr) {
        res.steamAddr.forEach((ibs, index) => {
          arrs.push({
            url: ibs.url,
            type: ibs.type,
            enable: "",
          });
          if (ibs.isfor) {
            arrs[index].enable = 1;
          } else {
            arrs[index].enable = 0;
          }
        });
      }
      if (res.videos.length > 0) {
        arrs.push({
          url: res.videos[0].url,
          type: res.videos[0].type,
          enable: 1,
        });
      }
      let arrs_b = [];
      if (res.deviceConfigDto.streamout) {
        res.deviceConfigDto.streamout.forEach((item, index) => {
          arrs_b.push({
            name: "",
            url: item,
          });
        });
      }
      let email = this.userxx.email;
      let params = {
        deviceMid: res.mid,
        email: email,
        name: "",
        nameUrls: arrs_b,
        pullUrl: arrs,
      };
      //console.log('kekk')
      //let dasa=await userApi.addEqUrl(params)
    },
    //停止播放
    handpausse() {
      this.player.destroy();
      if (this.$refs.video_a) {
        this.$refs.video_a.style.zIndex = "100";
      }
    },
    //开始播放
    handlePlay() {
      this.player.play();
    },
    //播放视频
    videos(mid, res, data) {
      //console.log(res.videos[0].url)
      if (res.status === 0) {
        this.$message.error(this.$t("lang.CannotEdit"));
        return false;
      }
      if (res.deviceRealtimeInfoDto.workModeStatus.workMode === 1) {
        this.$message.error(this.$t("lang.Youcanpreview"));
        return false;
      }
      if (
        res.deviceRealtimeInfoDto.workModeStatus.workMode === 0 &&
        res.deviceRealtimeInfoDto.workModeStatus.workStatus == 0
      ) {
        this.$message.error(this.$t("lang.Youcanpreview"));
        return false;
      }
      if (res.deviceConfigDto.encodeType === 1) {
        this.$message.error(this.$t("lang.Codingpreview"));
        return false;
      }

      //this.url=''
      //console.log(this.url)
      let arrs = [];
      data.forEach((ibs, index) => {
        arrs.push({
          url: ibs.url,
          type: ibs.type,
          enable: "",
        });
        if (ibs.isfor) {
          arrs[index].enable = 1;
        } else {
          arrs[index].enable = 0;
        }
      });
      if (res.videos.length > 0) {
        arrs.push({
          url: res.videos[0].url,
          type: res.videos[0].type,
          enable: 1,
        });
      }
      let arrs_b = [];
      res.deviceConfigDto.streamout.forEach((item, index) => {
        arrs_b.push({
          name: "",
          url: item,
        });
      });
      let email = this.userxx.email;
      let params = {
        deviceMid: mid,
        email: email,
        name: "",
        nameUrls: arrs_b,
        pullUrl: arrs,
      };
      // console.log(JSON.parse(this.devequipmen.adlist));
      let arrsad = JSON.parse(this.devequipmen.adlist);
      arrsad.pullStream.forEach((ims) => {
        if (ims.type == 3) {
          this.url = ims.url;
        }
      });

      setTimeout(() => {
        this.url = this.devequipmen.videos[0].url;
        var video = document.getElementById("video-webrtc");

        this.player = new JSWebrtc.Player(this.url, {
          video: video,
          autoplay: true,
        });
        this.$refs.video_a.style.zIndex = "90";
      }, 1000);
      // userApi.addEqUrl(params).then((res) => {
      //   setTimeout(() => {

      //     this.url = this.devequipmen.videos[0].url;
      //     var video = document.getElementById("video-webrtc");

      //     this.player = new JSWebrtc.Player(this.url, {
      //       video: video,
      //       autoplay: true,
      //     });
      //     this.$refs.video_a.style.zIndex = "90";
      //   }, 1000);
      // });
    },
    // 信号折线图
    dlistChart() {
      let thia = this;
      // console.log(this.devequipmen,555222)
      if (this.devequipmen.linkInfo && this.devequipmen.linkInfo.length > 0) {
        
        this.devequipmen.linkInfo.forEach((items) => {
          // items.enable = (items.enable>=1 ?  true:false);
          let arr = [];
          let sk = 0;
          items.recvRate.forEach((ims) => {
            // ims = parseInt(Number(ims) / 1024 / 1024);
            arr.push(((Number(ims) * 8) / 1024).toFixed(2));
            // ims = parseInt((Number(ims) * 8) / 1024);
          });
          items.recvRate = arr;
          items.recvRate.forEach((imads)=>{
            sk = sk + parseInt(imads);
          })
          items.tabekbs = (sk / 20).toFixed(2) + " kbps";
          // console.log(arr)
        });
        
      }
      this.$nextTick(() => {
        this.devequipmen.linkInfo.forEach((ims, idx) => {
          // console.log(ims.recvRate);

          // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
          let myChart = this.$echarts.init(
            document.getElementById("echasads" + idx)
          );
          // 指定图表的配置项和数据
          let option = {
            tooltip: {
              trigger: "axis",
              formatter: function (res) {
                return (
                  "</div>" +
                  "<div>" +
                  thia.$t("lang.bitRate") +
                  "：" +
                  res[0].data +
                  " kbps" +
                  "</div>"
                );
              },
            },
            title: {
              subtext: ims.tabekbs,
              x: "right",
              padding: [5, 40, 2, 7],
            },
            xAxis: {
              type: "category",
              name: "S",
              data: [20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1,],
              boundaryGap: false,
              axisTick: {
                show: false,
              },
            },
            yAxis: {
              type: "value",
              name: "kbps",
              axisTick: {
                show: false,
              },
              nameTextStyle: {
                // padding: [10, 10, 0, 5],
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: ["#B2B2B2"],
                  width: 1,
                  type: "solid",
                },
              },
            },
            series: [
              {
                data: ims.recvRate,
                type: "line",
                smooth: true,
                showSymbol: false,
                itemStyle: {
                  normal: {
                    areaStyle: {
                      type: "default", //渐变色实现===
                      color: new thia.$echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            color: "#2E7135",
                          },
                          {
                            offset: 1,
                            color: "#000000",
                          },
                        ]
                      ),
                    },
                    lineStyle: {
                      color: "#2E7135",
                    }, //线的颜色
                    label: {
                      show: false,
                      position: "top",
                      textStyle: {
                        color: "#B7BAC5",
                      }, //以及在折线图每个日期点顶端显示数字
                    },
                  },
                },
              },
            ],
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        });
      });
    },
    //初始化折线图
    drawChart() {
      let thia = this;
      // 基于准备好的dom，初始化echarts实例
      thia.myChart = thia.$echarts.init(document.getElementById("mainsa"));
      let option = {
        tooltip: {
          trigger: "axis",
          // show: false "<div>"+thia.$t("lang.coordinate")+"："+res[0].axisValue+
          formatter: function (res) {
            return (
              "</div>" +
              "<div>" +
              thia.$t("lang.bitRate") +
              "：" +
              res[0].data +
              " kbps" +
              "</div>"
            );
          },
        },
        title: {
          subtext: thia.tabekbs,
          x: "right",
          padding: [5, 40, 2, 7],
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            // show: false,
            name: "S",
            data : thia.default
          },
        ],
        yAxis: [
          {
            type: "value",
            // min:0,
            // max:15360,
            axisTick: {
              show: false,
            },
            name: "kbps",
            nameTextStyle: {
              padding: [30, 50, 0, 5],
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#B2B2B2"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],
        grid: {
          left: "20px",
          right: "30px",
          bottom: "10px",
          top: "40px",
          containLabel: true,
        },
        series: [
          {
            name: "kbps",
            type: "line",
            smooth: true,
            showSymbol: false,
            itemStyle: {
              normal: {
                areaStyle: {
                  type: "default", //渐变色实现===
                  color: new thia.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#2E7135",
                    },
                    {
                      offset: 1,
                      color: "#000000",
                    },
                  ]),
                },
                lineStyle: {
                  color: "#2E7135",
                }, //线的颜色
              },
            },
            stack: "总量",
            data: thia.devequipmen.Linechart
              ? thia.devequipmen.Linechart
              : thia.defaults,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      thia.myChart.setOption(option);
    },
    unique(arr) {
      return arr.filter(function (item, index, arr) {
        //当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
        return arr.indexOf(item.url, 0) === index;
      });
    },
    //操作
    refreshDeviceMsg(item) {
      this.cosnts = 0;
      this.cosntsa = 0;
      //console.log(item)
      //this.handleRecord(item.recordStatus, item);
    },
    /**************页面功能********************/
    //设置码率
    changeList(e, item) {
      this.cosnts = 0;
      this.cosntsa = 0;
      //console.log(e)
      if (!item.status) {
        this.$message({
          message: this.$t("lang.CannotEdit"),
          type: "error",
        });
        return;
      }
      clearInterval(this.getdev);
      let params = {
        adminEmail: this.userxx.email,
        mid: item.mid,
        name: item.name,
        videoBitrate: e,
        cache: item.deviceDefaults.cache,
        videoCbr: item.deviceDefaults.videoCbr,
        encodeFmt: item.deviceDefaults.encodeFmt,
        encodeFps: item.deviceDefaults.encodeFps,
        encodeType: item.deviceDefaults.encodeType,
        outputPolicy: item.deviceDefaults.outputPolicy,
        protocol: item.deviceDefaults.protocol,
        recordStatus: item.deviceConfigDto.recordStatus,
        workMode: item.deviceDefaults.workMode,
      };
      equipmentApis.setDevice(params).then((res) => {
        if (res.data) {
          this.$message.success(this.$t("lang.Operations"));
          this.$set(item.deviceDefaults, "videoBitrate", e);
          this.getdev = setInterval(() => {
            this.getsea();
          }, 1000);
        }
      });
    },
    //设置缓冲
    changeListTwo(e, item) {
      this.cosnts = 0;
      this.cosntsa = 0;
      if (!item.status) {
        this.$message({
          message: this.$t("lang.CannotEdit"),
          type: "error",
        });
        return;
      }
      clearInterval(this.getdev);
      let params = {
        adminEmail: this.userxx.email,
        mid: item.mid,
        name: item.name,
        videoBitrate: item.deviceDefaults.videoBitrate,
        cache: e,
        videoCbr: item.deviceDefaults.videoCbr,
        encodeFmt: item.deviceDefaults.encodeFmt,
        encodeFps: item.deviceDefaults.encodeFps,
        encodeType: item.deviceDefaults.encodeType,
        outputPolicy: item.deviceDefaults.outputPolicy,
        protocol: item.deviceDefaults.protocol,
        recordStatus: item.deviceConfigDto.recordStatus,
        workMode: item.deviceDefaults.workMode,
      };
      equipmentApis.setDevice(params).then((res) => {
        if (res.data) {
          this.$message.success(this.$t("lang.Operations"));
          this.$set(item.deviceDefaults, "cache", e);
          this.getdev = setInterval(() => {
            this.getsea();
          }, 1000);
        }
      });
    },
    // 点击拉流开关
    switceChangeOne(e, mid, resa, data, item) {
      console.log(item);
      if (resa.status === 0) {
        this.$message.error(this.$t("lang.CannotEdit"));
        return false;
      }
      let arrs = [];
      data.forEach((ibs, index) => {
        arrs.push({
          url: ibs.url,
          type: ibs.type,
          enable: "",
        });
        if (ibs.isfor) {
          arrs[index].enable = 1;
        } else {
          arrs[index].enable = 0;
        }
      });
      let arrs_b = [];
      resa.deviceConfigDto.streamout.forEach((item, index) => {
        arrs_b.push({
          name: "",
          url: item,
        });
      });
      let email = this.userxx.email;
      let params = {
        deviceMid: mid,
        email: email,
        name: "",
        nameUrls: arrs_b,
        pullUrl: arrs,
      };
      userApi.addEqUrl(params).then((res) => {
        this.$message({
          message: this.$t("lang.Operations"),
          type: "success",
        });
      });
      this.$set(item, "isfor", !item.isfor);
    },
    // 点击复制
    fuzhiUrl(urls) {
      this.$copyText(urls).then(
        (res) => {
          this.$message.success(this.$t("lang.Copysucceeded"));
        },
        (error) => {
          this.$message.success(this.$t("lang.copyfailed"));
        }
      );
    },
    //设置
    handleSetDevice(item) {
      if (!item.status) {
        this.$message({
          message: this.$t("lang.CannotEdit"),
          type: "error",
        });
        return;
      }

      this.$refs.setDevice.open(item);
    },
    //设备详情
    handleDetail(item) {
      this.$router.push({
        path: "/nav/user/equipmentmsg",
        query: {
          mid: item.mid,
        },
      });
    },
    //导入方案
    handleImportPlan(item, pullstreamout) {
      if (!item.status) {
        this.$message({
          message: this.$t("lang.CannotEdit"),
          type: "error",
        });
        return;
      }
      this.$refs.importPlan.open(item, pullstreamout);
    },

    //添加推流地址
    addPushName(item, data, steamAddr) {
      if (item.status === 0) {
        this.$message.error(this.$t("lang.CannotEdit"));
        return false;
      }
      if (data.length < 4) {
        this.$refs.addFurl.open(item, data, steamAddr);
      } else {
        this.$message.error(this.$t("lang.vbs"));
      }
    },
    //开始、结束录制
    handleStartRecord(item, index) {
      if (
        item.deviceRealtimeInfoDto.workModeStatus.workMode === 0 &&
        item.deviceRealtimeInfoDto.workModeStatus.workStatus == 0
      ) {
        this.$message.error(this.$t("lang.canonlyberecorded"));
        return false;
      }
      let params = {
        adminEmail: this.userxx.email,
        mid: item.mid,
        name: item.name,
        videoBitrate: item.deviceDefaults.videoBitrate,
        cache: item.deviceDefaults.cache,
        videoCbr: item.deviceDefaults.videoCbr,
        encodeFmt: item.deviceDefaults.encodeFmt,
        encodeFps: item.deviceDefaults.encodeFps,
        encodeType: item.deviceDefaults.encodeType,
        outputPolicy: item.deviceDefaults.outputPolicy,
        protocol: item.deviceDefaults.protocol,
        recordStatus: index,
        workMode: item.deviceDefaults.workMode,
      };
      equipmentApis.setDevice(params).then((res) => {
        if (res.data) {
          this.$message.success(this.$t("lang.Operations"));
        }
      });
    },
    //结束收录
    handleStartRecords(item, index) {
      let params = {
        adminEmail: this.userxx.email,
        mid: item.mid,
        name: item.name,
        videoBitrate: item.deviceDefaults.videoBitrate,
        cache: item.deviceDefaults.cache,
        videoCbr: item.deviceDefaults.videoCbr,
        encodeFmt: item.deviceDefaults.encodeFmt,
        encodeFps: item.deviceDefaults.encodeFps,
        encodeType: item.deviceDefaults.encodeType,
        outputPolicy: item.deviceDefaults.outputPolicy,
        protocol: item.deviceDefaults.protocol,
        recordStatus: index,
        workMode: item.deviceDefaults.workMode,
      };
      equipmentApis.setDevice(params).then((res) => {
        if (res.data) {
          //console.log('kk')
        }
      });
    },
    // 取消绑定手机弹框
    clickRemove() {
      this.isShows = false;
    },
    // 绑定手机
    clickOk() {
      this.$router.push({
        path: "/nav/personal",
        query: { ind: 3 },
      });
      this.isShows = false;
    },
    changeClick(e) {
      this.getBindPhoneStatus(e);
    },
    // 验证是否绑定手机
    getBindPhoneStatus(checked) {
      let remind = "";
      if (checked) {
        remind = 1;
      } else if (checked === false) {
        remind = 0;
      }
      let email = this.userxx.email;
      let data = {
        email: email,
        remind: remind,
      };

      Apis.getBindPhoneStatus(data).then((res) => {
        if (res.data.remind == 1) {
          //this.isShows = false;
          sessionStorage.setItem("getBindPhoneStatus", 1);
        }
        if (res.data.remind == 0 && res.data.isBindPhone == 0) {
          this.isShows = true;
          sessionStorage.setItem("getBindPhoneStatus", 1);
        }
        if (res.data.remind == 0 && res.data.isBindPhone == 1) {
          //this.isShows = false;
        }
      });
    },
    //删除推流地址
    handleDelUrl(items, data, index, pullstreamout) {
      if (items.status === 0) {
        this.$message.error(this.$t("lang.CannotEdit"));
        return false;
      }
      let pullstreamoutARR = [];
      pullstreamout.forEach((ibs, indexs) => {
        pullstreamoutARR.push({
          url: ibs.url,
          type: ibs.type,
          enable: "",
        });
        if (ibs.isfor) {
          pullstreamoutARR[indexs].enable = 1;
        } else {
          pullstreamoutARR[indexs].enable = 0;
        }
      });
      this.$confirm(
        this.$t("lang.ThisActionPermanentlyDeletesTheAddress") +
          this.$t("lang.WhetherOrNotToContinue"),
        this.$t("lang.Tips"),
        {
          confirmButtonText: this.$t("lang.determine"),
          cancelButtonText: this.$t("lang.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          let email = this.userxx.email;
          let soe = JSON.parse(JSON.stringify(data));
          soe.splice(index, 1);
          let sod = [];
          soe.forEach((item) => {
            sod.push({
              name: "",
              url: item.url,
            });
          });
          let params_a = {
            deviceMid: items.mid,
            email: email,
            name: "",
            nameUrls: sod,
            pullUrl: pullstreamoutARR,
          };
          Apis.addEqUrl(params_a).then(() => {
            this.$message({
              message: this.$t("lang.deletessuccess"),
              type: "success",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("lang.CanceledDelete"),
          });
        });
    },
    //开启关闭直播、录播
    liveclik(row) {
      // console.log(row);
      if (row.status === 0) {
        this.$message.error(this.$t("lang.CannotEdit"));
        return false;
      }
      //console.log(row.deviceRealtimeInfoDto.workModeStatus.workStatus)
      this.opens.mid = row.mid;
      this.opens.workMode = row.deviceRealtimeInfoDto.workModeStatus.workMode;
      if (row.deviceRealtimeInfoDto.workModeStatus.workStatus == 0) {
        this.balanas = row.balance;
        if (row.balance == 0 || row.balance < 0) {
          this.dialogarrars = true;
          this.monys = 3;
          this.arrertitle = this.$t("lang.Rechargereminder");
          return false;
        }
        this.opens.workStatus = 1;
        let tise = new Date().getTime();
        this.opens.workName = tiemsa.getTime(tise).setwa;
        if (row.deviceRealtimeInfoDto.workModeStatus.workMode == 0) {
          this.titles = this.$t("lang.Turnonlive");
        } else {
          this.titles = this.$t("lang.Startrecordings");
        }
        this.switchs = false;
        this.dialogs = true;
      }
      if (row.deviceRealtimeInfoDto.workModeStatus.workStatus == 1) {
        this.switchdata = row;
        if (row.deviceRealtimeInfoDto.workModeStatus.workMode == 0) {
          this.titles = this.$t("lang.Turnonlives");
        } else {
          this.titles = this.$t("lang.Startrecordingsa");
        }
        this.switchs = true;
        this.dialogs = true;
        //this.submitForm(0)
      }
    },
    async submitForm(ind) {
      if (ind === 0) {
        this.opens.workStatus = 0;
        this.opens.workName = "";
        if (this.switchdata.deviceConfigDto.recordStatus == 1) {
          this.handleStartRecord(this.switchdata, 0);
        }
        if (this.player) {
          this.handpausse();
        }
        this.$refs.video_a.style.zIndex = "100";
      }
      if (ind !== 0 && this.opens.workName == "") {
        this.$message.error(this.$t("lang.Pleaseenteraname"));
        return false;
      }
      if (ind !== 0 && !inttext(this.opens.workName)) {
        this.$message.error(this.$t("lang.Onlyentered"));
        return false;
      }
      let res = await Apis.openzb(this.opens);
      //console.log(res)
      if (res.data) {
        this.$message.success(this.$t("lang.Operations"));
        this.dialogs = false;
      }
    },
    //时间处理
    shownum(num) {
      if (num < 10) {
        return "0" + num;
      }
      return num;
    },
    //点击缓冲更改
    cars(res) {
      if (!res.status) {
        this.$message({
          message: this.$t("lang.CannotEdit"),
          type: "error",
        });
        return;
      }
      this.caches = res;
      this.cachess = res.deviceConfigDto.cache;
      this.cachesa = true;
      this.cosnts = 0;
      this.cosntsa = 0;
      setTimeout(() => {
        this.$refs.carinput.focus();
      }, 100);
      clearInterval(this.getdev);
    },
    //缓冲失去焦点
    cachbl() {
      if (
        integersa(this.cachess) &&
        this.cachess >= 500 &&
        this.cachess <= 30000
      ) {
        this.updast(this.caches, this.cachess);
      } else {
        this.$message.error(this.$t("lang.Pleaseenters"));
        this.getdev = setInterval(() => {
          this.getsea();
        }, 1000);
        setTimeout(() => {
          this.cachesa = false;
        }, 1000);
      }
    },
    cachbls() {
      this.$refs.carinput.blur();
    },
    //点击码率更改
    bits(res) {
      if (!res.status) {
        this.$message({
          message: this.$t("lang.CannotEdit"),
          type: "error",
        });
        return;
      }
      this.caches = res;
      this.bitess = res.deviceConfigDto.videoBitrate;
      this.bitesa = true;
      this.cosnts = 0;
      this.cosntsa = 0;
      setTimeout(() => {
        this.$refs.bitinput.focus();
      }, 100);
      clearInterval(this.getdev);
    },
    //码率失去焦点
    bitbl() {
      if (
        integersa(this.bitess) &&
        this.bitess >= 100 &&
        this.bitess <= 15360
      ) {
        this.updasts(this.caches, this.bitess);
      } else {
        this.$message.error(this.$t("lang.Pleaseentersa"));
        this.getdev = setInterval(() => {
          this.getsea();
        }, 1000);
        setTimeout(() => {
          this.bitesa = false;
        }, 1000);
      }
    },
    bitbls() {
      this.$refs.bitinput.blur();
    },
    //更新缓冲
    updast(item, e) {
      let params = {
        adminEmail: this.userxx.email,
        mid: item.mid,
        name: item.name,
        videoBitrate: item.deviceDefaults.videoBitrate,
        cache: e,
        videoCbr: item.deviceDefaults.videoCbr,
        encodeFmt: item.deviceDefaults.encodeFmt,
        encodeFps: item.deviceDefaults.encodeFps,
        encodeType: item.deviceDefaults.encodeType,
        outputPolicy: item.deviceDefaults.outputPolicy,
        protocol: item.deviceDefaults.protocol,
        recordStatus: item.deviceConfigDto.recordStatus,
        workMode: item.deviceDefaults.workMode,
      };
      setTimeout(() => {
        this.cachesa = false;
      }, 1500);
      equipmentApis.setDevice(params).then((res) => {
        if (res.data) {
          this.$message.success(this.$t("lang.Operations"));
          this.$set(item.deviceDefaults, "cache", e);
          this.getsea();
          this.getdev = setInterval(() => {
            this.getsea();
          }, 1000);
        }
      });
    },
    //更新码率
    updasts(item, e) {
      let params = {
        adminEmail: this.userxx.email,
        mid: item.mid,
        name: item.name,
        videoBitrate: e,
        cache: item.deviceDefaults.cache,
        videoCbr: item.deviceDefaults.videoCbr,
        encodeFmt: item.deviceDefaults.encodeFmt,
        encodeFps: item.deviceDefaults.encodeFps,
        encodeType: item.deviceDefaults.encodeType,
        outputPolicy: item.deviceDefaults.outputPolicy,
        protocol: item.deviceDefaults.protocol,
        recordStatus: item.deviceConfigDto.recordStatus,
        workMode: item.deviceDefaults.workMode,
      };
      setTimeout(() => {
        this.bitesa = false;
      }, 1500);
      equipmentApis.setDevice(params).then((res) => {
        if (res.data) {
          this.$message.success(this.$t("lang.Operations"));
          this.$set(item.deviceDefaults, "videoBitrate", e);
          this.getsea();
          this.getdev = setInterval(() => {
            this.getsea();
          }, 1000);
        }
      });
    },
    //去充值
    toRecharge() {
      this.$router.push({
        path: "/nav/personal",
        query: { ind: 5 },
      });
    },
    rightClick(e, index,num) {
      if(Number(num) > 10){
        return
      }
      this.activeIndex = index;
      var menu = document.getElementById("menu" + index);
      this.visibles = true;
      menu.style.left = e.pageX + "px";
      menu.style.top = e.pageY + "px";
    },
    editDeviceLinkCtrlApi(item, index) {
      let parms = {
        control: index,
        link_type: item.type,
        link_index: item.usb_index,
        mid: this.devequipmen.mid,
      };
      userApi.editDeviceLinkCtrl(parms).then((res) => {
        this.$message({
          message: this.$t("lang.Operations"),
          type: "success",
        });
        var menu = document.getElementById("menu");
        this.visibles = false;
      });
    },
    tonumbers(item, index) {
      let parms = {
        mode: index,
        link_index: item.usb_index,
        mid: this.devequipmen.mid,
      };
      userApi.deviceLinkNetworkModeSet(parms).then((res) => {
        this.$message({
          message: this.$t("lang.Operations"),
          type: "success",
        });
        this.visibles = false;
      });
    },
    closeMenu() {
      this.visibles = false;
    },
  },
  beforeDestroy() {
    //console.log(this.player.paused)
    if (this.player.paused == false) {
      this.handpausse();
    }
    if (this.getdev) {
      clearInterval(this.getdev);
    }
    if (this.charttimell) {
      clearInterval(this.charttimell);
    }
    if (this.chartimes) {
      clearInterval(this.chartimes);
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.el-checkbox__label {
  color: #438749 !important;
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #438749 !important;
}

/deep/.is-checked,
.el-checkbox__inner {
  border-color: #438749 !important;
}

/deep/.el-checkbox__inner:hover {
  border-color: #438749 !important;
}

/deep/.el-slider__bar {
  height: 0.15rem !important;
  border-color: #438749 !important;
  background-color: #438749 !important;
}

/deep/.el-slider__runway {
  height: 0.15rem !important;
}

/deep/.el-slider__button-wrapper {
  height: 0.15rem !important;
  top: -0.05rem;
}

/deep/.el-slider__button {
  border-color: #6b7c6d !important;
}

/deep/.el-slider__stop {
  height: 0.15rem !important;
  width: 0.18rem !important;
}

/deep/.el-slider__marks-text {
  margin-top: 0.22rem !important;
}

/deep/.el-slider__button {
  width: 0.18rem !important;
  height: 0.18rem !important;
}

.bgcard {
  width: 100%;
  padding: 0.19rem 0.17rem;
  box-sizing: border-box;
  border-radius: 0.02rem;
  background-color: rgba(034, 038, 040, 0.76);
}

.bgcolor {
  min-height: calc(100vh - 1.7rem);
  background: #0f1316;
  border-radius: 0.02rem;
  padding: 0 0.19rem 0.15rem 0.38rem;
  box-sizing: border-box;
}

.equimentheader {
  display: flex;
  justify-content: space-between;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  box-sizing: border-box;

  .equimentheaders {
    display: flex;
    flex-flow: column;
    width: 57%;

    .equimentheader_a {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      border-bottom: 1px solid #2a2d2f;
      padding-bottom: 0.1rem;
    }

    .devxx_left {
      color: #b2b2b2;
      margin-top: 0.15rem;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      padding-left: 0.1rem;
      box-sizing: border-box;
      width: 100%;

      .msgleft {
        .msgone {
          display: flex;
          align-items: center;
          margin-bottom: 0.15rem;

          img {
            width: 0.23rem;
            margin-right: 0.08rem;
          }
        }

        .statemsg {
          margin-right: 0.1rem;
          display: flex;
          align-items: center;

          p {
            font-size: 0.15rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #b2b2b2;
          }

          .statemsg_a {
            display: inline-block;
            width: 0.34rem;
            height: 0.17rem;
            border: 0.02rem solid #307b37;
            border-radius: 0.03rem;
            padding: 0.01rem;
            position: relative;

            .state {
              background-color: #307b37;
              height: 100%;
            }

            .states {
              background-color: #d70000;
            }

            img {
              position: absolute;
              top: 0.03rem;
              right: 50%;
              display: block;
              width: 0.1rem;
              transform: translateX(50%);
            }
          }
          .statemsg_b {
            border-color: #d70000;
          }

          .state_a {
            width: 0.02rem;
            height: 0.1rem;
            background-color: #307b37;
          }
          .state_b {
            background-color: #d70000;
          }
        }
      }

      .msgright {
        .speedtime {
          display: flex;
          flex-flow: row;
          justify-content: space-between;

          span {
            margin-left: 0.1rem;
            display: inline-block;
            margin-right: 0.1rem;
          }
        }
      }
    }

    .urlleft {
      margin-top: 0.2rem;
      width: 100%;
      margin-left: 0.08rem;

      .showname {
        margin-top: 0.2rem;

        .over-name {
          width: 3.5rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 0.35rem;
        }

        .over-names {
          align-items: center;
          color: #307b37;
          cursor: pointer;
          font-size: 0.15rem;
          margin: 0 1rem;
        }

        .shownames {
          display: flex;
          flex-flow: row;
          align-items: center;
          font-weight: 400;
          font-size: 0.18rem;
          font-family: Microsoft YaHei;
          color: #b2b2b2;
        }

        .showname_a {
          display: flex;
          flex-flow: row;
          align-items: center;

          div {
            width: 1.85rem;
            margin-right: 0.1rem;
          }
        }
      }

      .addname {
        width: 0.6rem;
        height: 0.32rem;
        background: #d4d4d4;
        border-radius: 50%;
        font-size: 0.3rem;
        display: inline-block;
        position: relative;
        top: 0.05rem;
        cursor: pointer;

        span {
          position: relative;
          top: -0.07rem;
          left: 0.05rem;
        }
      }
    }
  }

  .equimentheader_b {
    display: flex;
    flex-flow: column;

    .devxx_right {
      width: 5.6rem;
      height: 2.7rem;
      box-sizing: border-box;
      background-color: #222628;
      border-radius: 2px;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;

      .devxx_rights {
        width: 5.3rem;
        height: 2.4rem;
        position: relative;
        overflow: hidden;

        .devxx_video {
          position: absolute;
          width: 5.3rem;
          height: 2.4rem;
          background-image: url(../../../../assets/imgs/devlis/videosa.png);
          background-size: 100% 100%;
          display: flex;
          flex-flow: row;
          z-index: 100;
          justify-content: center;
          align-items: center;

          img {
            width: 0.5rem;
            height: 0.5rem;
          }
        }

        video {
          left: 0;
          position: absolute;
          z-index: 99;
          background-color: black;
          width: 5.3rem;
          height: 2.4rem;
        }
      }
    }
  }

  .imgcard {
    width: 1rem;
    height: 1rem;
    box-sizing: border-box;
    margin-right: 0.16rem;
    border-radius: 8px;
    position: relative;

    .imgcard_a {
      width: 100%;
      height: 100%;
      display: inline-block;
    }

    .displayimg {
      position: absolute;
      width: 0.16rem;
      height: 0.16rem;
      border-radius: 50%;
      right: -0.06rem;
      bottom: -0.03rem;
    }
  }

  .eqleft {
    display: flex;
    align-items: center;
    margin-left: 3px;
  }

  .eqcontent {
    .deviceName {
      font-size: 0.2rem;
      color: #b2b2b2;
      font-family: Microsoft YaHei;
      font-weight: 400;
      padding-bottom: 0.1rem;
      width: 3.6rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      margin-bottom: 0.12rem;

      &:first-of-type {
        font-size: 0.18rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b2b2b2;
      }

      &:nth-of-type(2) {
        padding-left: 1px;
        font-size: 0.18rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b2b2b2;
      }

      &:last-of-type {
        font-size: 0.16rem;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #b2b2b2;
        margin: 0px;
      }
    }
  }

  .startlive {
    width: 1.52rem;
    height: 0.71rem;
    background: #222628;
    border: 3px solid #59595c;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    margin-right: 0.48rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: #b7b7b7;
    font-size: 0.14rem;
    cursor: pointer;
  }

  .startlives {
    width: 1.52rem;
    height: 0.71rem;
    background: #222628;
    border: 3px solid #cf142f;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    margin-right: 0.48rem;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    color: #cf142f;
    font-size: 0.14rem;
    cursor: pointer;

    img {
      margin-right: 0.08rem;
      width: 0.32rem;
      height: 0.25rem;
    }
  }

  .startactive {
    background-color: #9f0000;
    color: #fff;
    border: 3px solid #9f0000;
  }
}

.eqcenter {
  height: 93px;
  display: flex;
  align-items: flex-end;
  cursor: default;

  > p {
    font-size: 0.36rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #b2b2b2;
    margin-right: 0.8rem;
    margin-top: 0.76rem;
  }

  .recording {
    cursor: pointer;
    // width: 152px;
    height: 0.59rem;
    padding: 0 0.1rem;
    background: #307b37;
    border-radius: 0.1rem;
    display: flex;
    // justify-content: start;
    align-items: center;
    margin-top: 0.53rem;

    img {
      // margin: 13px 6px 12px 10px;
      margin-right: 0.05rem;
      width: 0.43rem;
      height: 0.34rem;
    }

    .tips {
      font-size: 0.2rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.35rem;
      text-align: center;
    }

    p {
      font-size: 0.2rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.35rem;
    }
  }

  .endrec {
    margin-top: 0.53rem;

    button {
      width: 1.52rem;
      height: 0.59rem;
      outline-style: none;
      border: none;
      background: #9f0000;
      border-radius: 0.1rem;

      p {
        color: #ffffff;

        &:first-of-type {
          font-size: 0.2rem;
          font-family: PingFang SC;
          font-weight: bold;
        }

        &:last-of-type {
          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}

.funt {
  margin-right: 0.1rem;
  display: flex;
  align-items: center;

  .funts {
    display: inline-block;
    height: 0.18rem;
    margin: 0 0.1rem;
    width: 2px;
    background-color: #5293c5;
  }

  img {
    height: 0.26rem;
  }
}

.line {
  /* 线 */
  // width: 1421px;
  height: 1px;
  background: #797979;
  opacity: 0.28;
  margin-bottom: 0.22rem;
}

/* 设备信息 */
.eqmsg {
  display: flex;

  .msgleft {
    width: 3rem;

    .msgrow {
      display: flex;
      align-items: center;
      margin-bottom: 0.16rem;

      p {
        font-size: 0.18rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #b2b2b2;
        line-height: 0.35rem;
      }
    }

    .msgone {
      margin-left: 0.1rem;

      img {
        width: 0.23rem;
        height: 0.16rem;
        margin-right: 0.08rem;
      }
    }

    .msgtwo {
      margin-left: 0.1rem;

      img {
        width: 0.21rem;
        height: 0.19rem;
        margin-right: 0.1rem;
      }
    }

    .msgthree {
      margin-left: 0.1rem;

      img {
        width: 0.22rem;
        height: 0.19rem;
        margin-right: 0.09rem;
      }
    }
  }

  .msgcenter {
    margin-top: 3px;
    margin-left: 0.1rem;

    .eleamount {
      display: flex;
      align-items: center;

      // margin-bottom: .5rem;
      img {
        width: 0.37rem;
        height: 0.2rem;
        margin-right: 0.08rem;
      }

      p {
        &:first-of-type {
          font-size: 0.18rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #b2b2b2;
          line-height: 0.35rem;
          margin-right: 0.25rem;
        }

        &:last-of-type {
          font-size: 0.14rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #b2b2b2;
          line-height: 0.35rem;
          margin-left: 0.08rem;
        }
      }
    }

    .diskspace {
      // width: 380px;
      display: flex;
      align-items: center;

      p {
        font-size: 0.18rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #b2b2b2;
        line-height: 0.35rem;
        margin-right: 0.23rem;
      }

      .available {
        width: 1.33rem;
        height: 0.13rem;
        background: #307b37;
      }

      .common {
        width: 1.27rem;
        height: 0.13rem;
        background: #dbdbdb;
      }
    }

    .room {
      display: flex;
      justify-content: space-between;
      // margin-left: 100px;
      color: #b2b2b2;
      font-size: 0.16rem;

      p {
        font-size: 0.14rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b2b2b2;
        line-height: 0.35rem;

        &:first-of-type {
          margin-right: 1.51rem;
        }
      }
    }
  }

  .msgright {
    display: flex;
    margin-left: 0.5rem;

    .speed {
      margin-top: 0.1rem;

      &:first-of-type {
        margin-right: 0.34rem;
      }

      > p {
        font-size: 0.2rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b2b2b2;
        margin-bottom: 0.2rem;
      }

      .speedtime {
        display: flex;
        justify-content: space-between;
        font-size: 0.16rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b2b2b2;
        // margin-top: 2px;
      }

      .progressbar {
        /* 进度条 */
        display: flex;
        align-items: center;

        .radioleft {
          width: 1.38rem;
          height: 0.13rem;
          background: #307b37;
          border-radius: 6px;
          margin-right: -0.11rem;
        }

        .posradio {
          width: 0.22rem;
          height: 0.22rem;
          background: #ffffff;
          border-radius: 50%;
          z-index: 1;
        }

        .radioright {
          width: 1.41rem;
          height: 0.13rem;
          background: #dbdbdb;
          border-radius: 0.06rem;
          margin-left: -0.11rem;
        }
      }
    }
  }
}

/deep/.cardtap {
  display: inline-block;
  padding: 0 0.14rem;
  height: 0.25rem;
  line-height: 0.25rem;
  box-sizing: border-box;
  background: #dbdbdb;
  border-radius: 0.4rem;

  .el-input {
    height: 0.2rem;
    display: block;

    input {
      height: 0.2rem;
      border: none;
      padding: 0 0.1rem;
      width: 1rem;
    }
  }
}

.url {
  width: 55%;
  display: flex;
  align-items: center;
  margin-left: 0.08rem;

  p {
    // width: 108px;
    font-size: 0.2rem;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #b2b2b2;
    margin-right: 0.14rem;
  }

  .lines {
    // width: 100%;
    flex: 1;
    height: 1px;
    background: #797979;
    opacity: 0.28;
  }

  button {
    // width: 94px;
    height: 0.37rem;
    padding: 0 5px;
    outline-style: none;
    border: none;
    background: #307b37;
    border-radius: 4px;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #dbdbdb;
    margin-right: 0.21rem;
  }
}

/* 模态框 */
.setmsg {
  display: flex;

  .setmsgleft {
    margin-right: 0.3rem;
  }

  select {
    width: 2rem;
    height: 0.42rem;
    border: none;
    outline-style: none;
    background: #f8f8f8;
    border: 1px solid #d5d5d5;
    border-radius: 2px;
  }

  .listmsg {
    display: flex;
    align-items: center;
    margin-bottom: 0.15rem;

    p {
      width: 0.69rem;
      height: 0.16rem;
      font-size: 0.16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-right: 0.11rem;
    }

    input {
      width: 2rem;
      height: 0.42rem;
      background: #f8f8f8;
      border: 1px solid #d5d5d5;
      border-radius: 2px;
      outline: none;
      text-indent: 0.2rem;
    }
  }

  .setmsgright {
    .listmsg {
      span {
        margin-left: 0.09rem;
      }

      &:nth-of-type(2) {
        input {
          width: 1.68rem;
        }
      }

      &:nth-of-type(3) {
        input {
          width: 1.68rem;
        }
      }

      &:nth-of-type(4) {
        input {
          width: 1.68rem;
        }
      }
    }
  }
}

.addequipment {
  height: 2.4rem;
  background: linear-gradient(0deg, #0b1114, #0b0f12);
  opacity: 0.84;
  display: flex;
  align-items: center;
  justify-content: center;

  .msgeqipment {
    width: 4.7rem;
    height: 1.5rem;
    border: 1px dashed #ababab;
    border-radius: 0.1rem;

    p {
      text-align: center;
      line-height: 1.5rem;

      span {
        font-size: 0.36rem;
        display: inline-block;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #a1a1a1;

        &:first-of-type {
          margin-right: 0.23rem;
        }
      }
    }
  }
}

.choiceplan {
  margin-top: 0.21rem;
  display: flex;
  flex-direction: column;
}

.speed-box {
  display: flex;
  align-items: center;
  color: #b2b2b2;
  font-size: 0.18rem;

  .speed-val {
    width: 0.74rem;
    height: 0.25rem;
    line-height: 0.25rem;
    text-align: center;
    font-size: 14px;
    margin-left: 0.5rem;
    background-color: #dbdbdb;
    color: #797979;
    border-radius: 2px;
  }
}

/deep/.el-radio {
  //从这里开始 就是更改颜色的代码，将你的颜色           改掉我的颜色就可以了
  .el-radio__label {
    color: #000 !important;
  }

  .el-radio__input {
    .el-radio__inner {
      border-radius: 0;
      background-color: #fff;
      border-color: #ccc;
    }
  }

  .el-radio__input.is-checked .el-radio__inner::after {
    content: "";
    width: 0.08rem;
    height: 0.03rem;
    border: 2px solid #333;
    border-top: transparent;
    border-right: transparent;
    text-align: center;
    display: block;
    position: absolute;
    top: 2px;
    left: 1px;
    vertical-align: middle;
    transform: rotate(-45deg);
    border-radius: 0px;
    background: none;
  }
}

// .bitrate-item {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   color: #307b37;
// }
.orangeColor {
  color: #307b37 !important;
  height: 100%;
}

.modals {
  // display: flex;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .modals_posi {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    .modals_center {
      width: 4.2rem;
      // height: 4.15rem;
      background: #fff;
      // position: absolute;
      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;
      // margin: auto;

      .modals_top {
        padding: 0.18rem 0;
        background: #dddddd;
        text-align: center;
        font-size: 0.24rem;
      }

      .modals_img {
        width: 0.53rem;
        height: 0.87rem;
        margin: 0.27rem auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .modals_text {
        padding: 0 0.6rem;
        font-size: 0.15rem;
        color: #333333;
        text-align: center;
      }

      .checkboxs {
        text-align: center;
        padding: 0.15rem 0;
      }

      .modals_bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.15rem;
        padding-bottom: 15px;
        box-sizing: border-box;

        .allcl {
          width: 0.79rem;
          height: 0.4rem;
          border-radius: 0.3rem;
          text-align: center;
          line-height: 0.4rem;
          font-size: 0.15rem;
        }

        .modals_bottom_remove {
          background: #fff;
          border: 0.02rem solid #438749;
          color: #438749;
        }

        .modals_bottom_op {
          background: #438749;
          color: #ededed;
          margin-left: 0.45rem;
        }
      }
    }
  }
}

.xinhao1 {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}

.xinhao {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  position: relative;
  top: -0.03rem;

  div:nth-child(1) {
    width: 2px;
    height: 4px;
    background-color: #307b37;
    border-radius: 2px;
    margin-right: 2px;
  }

  div:nth-child(2) {
    width: 2px;
    height: 8px;
    background-color: #307b37;
    border-radius: 2px;
    margin-right: 2px;
  }

  div:nth-child(3) {
    width: 2px;
    height: 12px;
    background-color: #307b37;
    border-radius: 2px;
    margin-right: 2px;
  }

  div:nth-child(4) {
    width: 2px;
    height: 16px;
    background-color: #307b37;
    border-radius: 2px;
  }
}

.switchs {
  display: flex;
  align-items: center;
  padding: 20px 0;

  .switce_ad {
    display: flex;
    align-items: center;
    margin-right: 30px;

    .titles {
      padding: 0 10px;
      color: #b2b2b2;
    }

    .fizhi {
      color: #307b37;
      cursor: pointer;
    }
  }
}

.urlright {
  margin-top: 0.1rem;
  width: 100%;
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-wrap: wrap;

  .bitmsg {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 4rem;
    // height: 2.2rem;
    background: #222628;
    border-radius: 0.1rem;
    padding: 0.12rem 0.08rem;
    margin-bottom: 0.1rem;
    margin-right: 0.1rem;
    position: relative;
    height: 3.5rem;
    .bitrate-item {
      display: flex;
      flex-flow: row;
      // justify-content: space-between;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.lubo {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  img {
    width: 22px !important;
    height: 20px !important;
  }
}

.zhibo {
  color: #b2b2b2;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-right: 60px;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  img {
    width: 22px;
    height: 18px;
    margin-right: 5px;
  }
}

/deep/.devopenzb {
  .el-dialog__header {
    text-align: center;
    background-color: #dddddd;
    padding: 0;
    height: 0.59rem;
    line-height: 0.59rem;

    .el-dialog__headerbtn {
      display: none;
    }
  }

  .devzb {
    display: flex;
    align-items: center;
  }

  .dialog-footer {
    display: inline-block;
    margin-top: 0.3rem;
    display: flex;
    flex-flow: row;
    justify-content: center;

    button {
      width: 0.92rem;
      height: 0.46rem;
      background-color: #fff;
      border: 1px solid #438749;
      border-radius: 0.1rem;
      margin-right: 0.53rem;
      color: #438749;
    }

    button:nth-child(2) {
      background-color: #438749;
      border-radius: 0.1rem;
      color: #fff;
    }
  }
}

.zwdev {
  background-color: #0f1316;
  color: #b2b2b2;
  text-align: center;
  height: 0.5rem;
  line-height: 0.5rem;
}
/deep/ .arrears {
  .el-dialog__header {
    text-align: center;
    background-color: #dddddd;
    padding: 0;
    height: 0.5rem;
    line-height: 0.5rem;
    .el-dialog__headerbtn {
      display: none;
    }
  }
  .tipse {
    div {
      color: #333333;
      text-align: center;
      font-size: 0.16rem;
      line-height: 0.35rem;
    }
  }
  .dialog-footer {
    display: inline-block;
    margin-top: 0.3rem;
    display: flex;
    flex-flow: row;
    justify-content: center;

    button {
      width: 0.92rem;
      height: 0.46rem;
      background-color: #fff;
      border: 1px solid #438749;
      border-radius: 0.1rem;
      margin-right: 0.32rem;
      color: #438749;
      font-size: 0.16rem;
    }

    button:nth-child(2) {
      background-color: #438749;
      border-radius: 0.1rem;
      color: #fff;
      margin-right: 0;
    }
  }
}
/* 自定义右键菜单 */
.menulsit {
  position: fixed;
  z-index: 9999 !important;
  width: 150px;
  border: 1px solid #ccc;
  background: #eee;
}
.menulsit ul {
  margin: 5px 0;
}
.menulsit li {
  height: 30px;
  line-height: 30px;
  color: #21232e;
  font-size: 12px;
  text-align: center;
  cursor: default;
  list-style-type: none;
  border-bottom: 1px dashed #cecece;
}
.menulsit li:hover {
  background-color: #cccccc;
}

.lis {
  position: relative;
}
.menuls {
  display: none;
  position: absolute;
  z-index: 9999 !important;
  width: 150px;
  right: -152px;
  top: -6px;
  border: 1px solid #ccc;
  background: #eee;
}
.menulsit .lis:hover .menuls {
  display: block;
}
.menuls ul {
  margin: 5px 0;
}
.menuls li {
  height: 30px;
  line-height: 30px;
  color: #21232e;
  font-size: 12px;
  text-align: center;
  cursor: default;
  list-style-type: none;
  border-bottom: 1px dashed #cecece;
}
.menuls li:hover {
  background-color: #cccccc;
}
.spans {
  position: relative;
}
.imgadsright {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -40px;
  margin: auto;
}
#nodes:nth-child(3n + 0) {
  display: none;
}
</style>
